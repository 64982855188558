import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Sitemap from "./pages/Sitemap";
import AvisoLegal from "./pages/AvisoLegal";
import PoliticaPrivacidad from "./pages/PoliticaPrivacidad";
import PoliticaInocuidad from "./pages/PoliticaInocuidad";
import PoliticaCookies from "./pages/PoliticaCookies";
import { RUTAS } from "./constants/routes.constants";
import NotFound from "./pages/Page404";
import Ladestileria from "./pages/Ladestileria";
import Productos from "./pages/Productos";
import Contacto from "./pages/Contacto";
import SearchProduct from "./pages/SearchProduct";
import Descargas from "./pages/Descargas";
import BrandsPage from "./pages/BrandsPage";
import ResultsPage from "./pages/ResultsPage";
import QRS from "./pages/QRS";

function Rutas() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path={RUTAS.BASE.es} element={<Home />} />
          <Route path={RUTAS.BASE.ca} element={<Home />} />
          <Route path={RUTAS.BASE.en} element={<Home />} />

          <Route path={RUTAS.LADESTILERIA.es} element={<Ladestileria />} />
          <Route path={RUTAS.LADESTILERIA.en} element={<Ladestileria />} />

          <Route path={RUTAS.PRODUCTOS.es} element={<Productos />} />
          <Route path={RUTAS.PRODUCTOS.en} element={<Productos />} />

          <Route path={RUTAS.CONTACTO.es} element={<Contacto />} />
          <Route path={RUTAS.CONTACTO.en} element={<Contacto />} />

          <Route path={RUTAS.SEARCHPRODUCT.es[0]} element={<SearchProduct />} />
          <Route path={RUTAS.SEARCHPRODUCT.es[1]} element={<SearchProduct />} />
          <Route path={RUTAS.SEARCHPRODUCT.es[2]} element={<SearchProduct />} />
          <Route path={RUTAS.SEARCHPRODUCT.es[3]} element={<SearchProduct />} />
          <Route path={RUTAS.SEARCHPRODUCT.en[0]} element={<SearchProduct />} />
          <Route path={RUTAS.SEARCHPRODUCT.en[1]} element={<SearchProduct />} />
          <Route path={RUTAS.SEARCHPRODUCT.en[2]} element={<SearchProduct />} />
          <Route path={RUTAS.SEARCHPRODUCT.en[3]} element={<SearchProduct />} />

          <Route path={RUTAS.DESCARGAS.es} element={<Descargas />} />
          <Route path={RUTAS.DESCARGAS.en} element={<Descargas />} />

          <Route path={RUTAS.RESULTSPAGE.es} element={<ResultsPage />} />
          <Route path={RUTAS.RESULTSPAGE.en} element={<ResultsPage />} />

          <Route path={RUTAS.ABOUT.es} element={<AboutUs />} />
          <Route path={RUTAS.ABOUT.en} element={<AboutUs />} />

          <Route path={RUTAS.SITEMAP.es} element={<Sitemap />} />
          <Route path={RUTAS.SITEMAP.en} element={<Sitemap />} />

          <Route path={RUTAS.LEGAL.es} element={<AvisoLegal />} />
          <Route path={RUTAS.LEGAL.en} element={<AvisoLegal />} />

          <Route path={RUTAS.PRIVACIDAD.es} element={<PoliticaPrivacidad />} />
          <Route path={RUTAS.PRIVACIDAD.en} element={<PoliticaPrivacidad />} />

          <Route path={RUTAS.INOCUIDAD.es} element={<PoliticaInocuidad />} />
          <Route path={RUTAS.INOCUIDAD.en} element={<PoliticaInocuidad />} />

          <Route path={RUTAS.COOKIES.es} element={<PoliticaCookies />} />
          <Route path={RUTAS.COOKIES.en} element={<PoliticaCookies />} />

          <Route path={RUTAS.QRS.es} element={<QRS />} />
          <Route path={RUTAS.QRS.en} element={<QRS />} />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Rutas;
