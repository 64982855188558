/* eslint-disable jsx-a11y/anchor-is-valid */
import "./App.css";
import { FormattedMessage, injectIntl } from "react-intl";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useMemo, useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import logo from "./assets/logoblack.png";
import { useTexts } from "./hooks/useTexts";
import {
  setLanguage,
  setLanguageHome,
  getConfig,
  useLang,
} from "./translations/i18n";

import { RUTAS } from "./constants/routes.constants";
import TranslatedLink from "./components/navigation/TranslatedLink";
import ScrollToTop from "./components/ScrollToTop";
import {
  CookieConsentBanner,
  triggerCookieConsentBanner,
} from "@porscheofficial/cookie-consent-banner-react";
import icolinkedinwhite from "./assets/ico-linkedin-white.png";
import icoinstagramwhite from "./assets/ico-instagram-white.png";
import NavBar from "./components/navigation/NavBar";
import icolang from "./assets/ico-lang.svg";
import icoarrow from "./assets/ico-arrow.svg";
import icolupa from "./assets/lupa.svg";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { alertError, alertSuccess } from "./utils/logger";
import { postContact } from "./api/contact";
import CookieConsentPopUp from "./components/cookies/CookieConsentPopUp";
import AgeVerification from "./components/AgeConsent";
import kitdigital from "./assets/kit-digital-logo.png";

/*function getEmptyReceiver() {
	return {
		email: '',
		role: 'receiver'
	}
}*/

const formateLanguage = (lang) => {
  if (lang) {
    if (lang === "es") return "Español";
    else if (lang === "en") return "English";
  } else return "ESPAÑOL";
};

function shouldStickNavbar(path) {
  const mainRoutes = [
    "/",
    "/home",
    "/ladestileria",
    "/productos",
    "/contacto",
    "/descargas",
    "the-distilery",
    "products",
    "contact",
    "downloads",
  ];
  return mainRoutes.includes(path);
}

const Top = ({
  handleClick,
  menuOpen,
  pathname,
  handleChangeHome,
  handleChange,
  currentLoc,
}) => {
  const lang = useLang();
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const activeMenuItemRef = useRef(null);
  const [languageMenu, setLanguageMenu] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(formateLanguage(lang));
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(
      lang === "en"
        ? `/en/results?searchQuery=${searchQuery}`
        : `/resultados?searchQuery=${searchQuery}`
    );
  };

  return (
    <nav
      className={`navbar fixed-top sticky-nav navbar-expand-lg navbar-light apercubold`}
      style={{ backgroundColor: "#fff" }}
    >
      <div className="container-fluid">
        <TranslatedLink to={RUTAS.BASE}>
          <img
            src={logo}
            alt="Internacional de comercio y destilerías J Borrajo SA"
            className="logo"
          />
        </TranslatedLink>
        <button
          className="navbar-toggler"
          style={{ style: "none" }}
          type="button"
          data-bs-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={(e) => handleClick(e, menuOpen ? false : true)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="w-100">
          <div className="lang d-flex flex-row-reverse">
            <div className="position-relative">
              <button
                className="btnlang"
                onClick={() => setLanguageMenu(!languageMenu)}
              >
                <img
                  src={icolang}
                  className="icolang"
                  alt="Idioma/Language"
                  title="Idioma/Language"
                />{" "}
                <span className="mx-2 d-none d-lg-inline">
                  {currentLanguage}
                </span>{" "}
                <img
                  src={icoarrow}
                  alt="Idioma/Language"
                  title="Idioma/Language"
                />
              </button>
              {languageMenu && (
                <div className="languages position-absolute">
                  <div
                    onClick={() => {
                      setLanguage("es", pathname);
                      setCurrentLanguage("es");
                    }}
                  >
                    Español
                  </div>
                  <div
                    onClick={() => {
                      setLanguage("en", pathname);
                      setCurrentLanguage("en");
                    }}
                  >
                    English
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={clsx("navbar-collapse", menuOpen ? "" : " collapse")}
            id="navbarNav"
          >
            <ul className="navbar-nav d-flex justify-content-end align-items-lg-center w-100">
              {/* <NavBar /> */}
              <li className="nav-item active py-2 py-lg-0">
                <TranslatedLink
                  onClick={(event) => {
                    handleClick(event, 1);
                    handleClick(event, menuOpen ? false : true);
                    setActiveMenuItem(1);
                    activeMenuItemRef.current = event.currentTarget;
                  }}
                  ref={activeMenuItem === 1 ? activeMenuItemRef : null}
                  to={RUTAS.BASE}
                  className={clsx(
                    "nav-link",
                    pathname === `${RUTAS.BASE.es}` ||
                      pathname === `${RUTAS.BASE.en}`
                      ? " apercubold"
                      : ""
                  )}
                >
                  <FormattedMessage id="MENU.HOME" />
                </TranslatedLink>
              </li>
              <li className="d-none d-lg-block nav-item px-4">|</li>
              <li className="nav-item active py-2 py-lg-0">
                <TranslatedLink
                  onClick={(event) => {
                    handleClick(event, 2);
                    handleClick(event, menuOpen ? false : true);
                    setActiveMenuItem(2);
                    activeMenuItemRef.current = event.currentTarget;
                  }}
                  ref={activeMenuItem === 2 ? activeMenuItemRef : null}
                  to={RUTAS.LADESTILERIA}
                  className={clsx(
                    "nav-link",
                    pathname === `${RUTAS.LADESTILERIA.es}` ||
                      pathname === `${RUTAS.LADESTILERIA.en}`
                      ? " apercubold"
                      : ""
                  )}
                >
                  <FormattedMessage id="MENU.THEDISTILERY" />
                </TranslatedLink>
              </li>
              <li className="d-none d-lg-block nav-item px-4">|</li>
              <li className="nav-item active py-2 py-lg-0">
                <TranslatedLink
                  onClick={(event) => {
                    handleClick(event, 3);
                    handleClick(event, menuOpen ? false : true);
                    setActiveMenuItem(3);
                    activeMenuItemRef.current = event.currentTarget;
                  }}
                  ref={activeMenuItem === 3 ? activeMenuItemRef : null}
                  to={RUTAS.PRODUCTOS}
                  className={clsx(
                    "nav-link",
                    pathname === `${RUTAS.PRODUCTOS.es}` ||
                      pathname === `${RUTAS.PRODUCTOS.en}`
                      ? " apercubold"
                      : ""
                  )}
                >
                  <FormattedMessage id="MENU.PRODUCTS" />
                </TranslatedLink>
              </li>
              <li className="d-none d-lg-block nav-item px-4">|</li>
              <li className="nav-item active py-2 py-lg-0">
                <TranslatedLink
                  onClick={(event) => {
                    handleClick(event, 4);
                    handleClick(event, menuOpen ? false : true);
                    setActiveMenuItem(4);
                    activeMenuItemRef.current = event.currentTarget;
                  }}
                  ref={activeMenuItem === 4 ? activeMenuItemRef : null}
                  to={RUTAS.CONTACTO}
                  className={clsx(
                    "nav-link",
                    pathname === `${RUTAS.CONTACTO.es}` ||
                      pathname === `${RUTAS.CONTACTO.en}`
                      ? " apercubold"
                      : ""
                  )}
                >
                  <FormattedMessage id="MENU.CONTACT" />
                </TranslatedLink>
              </li>
              <li className="d-none d-lg-block nav-item px-4">|</li>
              <li className="nav-item active py-2 py-lg-0">
                <TranslatedLink
                  onClick={(event) => {
                    handleClick(event, 5);
                    handleClick(event, menuOpen ? false : true);
                    setActiveMenuItem(5);
                    activeMenuItemRef.current = event.currentTarget;
                  }}
                  ref={activeMenuItem === 5 ? activeMenuItemRef : null}
                  to={RUTAS.DESCARGAS}
                  className={clsx(
                    "nav-link",
                    pathname === `${RUTAS.DESCARGAS.es}` ||
                      pathname === `${RUTAS.DESCARGAS.en}`
                      ? " apercubold"
                      : ""
                  )}
                >
                  <FormattedMessage id="MENU.DOWNLOADS" />
                </TranslatedLink>
              </li>
            </ul>
            <form
              className="container-search position-relative py-2 py-lg-0 d-flex"
              onSubmit={handleSearch}
            >
              <input
                type="search"
                placeholder={lang === "en" ? "Search..." : "Buscar..."}
                aria-label="Search"
                className="form-control search-input"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onClick={(e) => e.stopPropagation()}
              />
              <button
                type="submit"
                className="button-no-style searchbar-button"
              >
                <img
                  src={icolupa}
                  className="icolupa position-absolute"
                  alt="buscar"
                  title="buscar"
                />
              </button>
            </form>
          </div>
        </div>
      </div>
    </nav>
  );
};

function loadTagManager(cookieValue) {}

function getEmptyContact() {
  return {
    name: "",
    email: "",
    subject: "",
    message: "",
  };
}

function App({ intl }) {
  const [contact, setContact] = useState(getEmptyContact());
  const [text, setText] = useState(null);
  const { pathname } = useLocation();
  const lang = useLang();
  const [menuOpen, setMenuOpen] = useState(false);
  const [acceptedCategories, setAcceptedCategories] = useState([]);
  const cookieValue = document.cookie;

  const location = useLocation();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyB6zewimvdujOIFAEjs3d9PwXBVICyfvqw",
  });
  const center = useMemo(() => ({ lat: 39.956663, lng: -4.842898 }), []);

  document
    .querySelector(".react-cookie")
    ?.shadowRoot?.querySelector(".cc_settings")
    ?.setAttribute("style", "padding-bottom: 0.5rem");
  document
    .querySelector(".react-cookie")
    ?.shadowRoot?.querySelector(".cc_settings_description")
    ?.setAttribute("style", "display: none");
  document
    .querySelector(".react-cookie")
    ?.shadowRoot?.querySelector(".cc_text")
    ?.setAttribute("style", "padding-bottom: 0.5rem; padding-top: 0.5rem");
  document
    .querySelector(".react-cookie")
    ?.shadowRoot?.querySelectorAll("button")
    .forEach((elem) =>
      elem.setAttribute("style", "font-size: 14px; padding: 0.5rem 1.5rem")
    );

  useEffect(() => {
    const cookies = cookieValue
      .split("; ")
      .find((row) => row.startsWith("cookies_accepted_categories="));
    if (cookies === undefined)
      triggerCookieConsentBanner({ showDetails: true });
    else if (cookies.includes("analytics") || cookies.includes("marketing"))
      loadTagManager(cookies);
  }, [cookieValue]);

  function handleClick(e, status) {
    e.stopPropagation();
    if (status) setMenuOpen(true);
    else setMenuOpen(false);
  }

  useEffect(() => {
    if (navigator.language === "en" && lang != "en") {
      setLanguage("en", pathname);
    } else if (
      ["es", "eu", "gl", "ca"].includes(navigator.language) &&
      lang != "es"
    ) {
      setLanguage("es", pathname);
    }
  }, [lang]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function saveContact() {
    postContact(contact)
      .then((res) => {
        if (res.status === 201) {
          //mail aviso a admin de nuevo reto
          alertSuccess({
            title: "Saved!",
            customMessage: intl.formatMessage({ id: "HOME.CONTACTCREATED" }),
          });
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: intl.formatMessage({ id: "HOME.COULDNOTSAVE" }),
        });
      });
  }

  const handleChangeContact = (element, lang) => (event) => {
    if (lang) {
      if (event.target.value === " ") return;
      if (!contact[element]) contact[element] = {};
      let newText = contact[element];
      newText[lang] = event.target.value;
      setContact({ ...contact, [element]: newText });
    } else setContact({ ...contact, [element]: event.target.value });
  };

  const handleChange = (element) => (event) => {
    setLanguage(element, pathname);
  };

  const handleChangeHome = (element) => () => {
    setLanguageHome(element);
  };

  let copyright = (
    <>
      Destilerías Borrajo
      <br />
      <FormattedMessage id="FOOTER.TODOSLOSDERECHOS" />
    </>
  );

  return (
    <div className="App" onClick={(e) => handleClick(e, false)}>
      {location.pathname != "/qr" && <AgeVerification />}
      {location.pathname != "/qr" && <CookieConsentPopUp />}
      <header style={{ position: "relative" }}>
        <div className="container-fluid">
          <Top
            handleClick={handleClick}
            menuOpen={menuOpen}
            pathname={pathname}
            handleChangeHome={handleChangeHome}
            handleChange={handleChange}
            currentLoc={pathname}
          />
        </div>
      </header>

      <Outlet />

      <ScrollToTop />

      <footer className="footer barlowregular">
        <div className="container">
          <div className="row py-5">
            <div className="col-12 col-lg-3">
              <div className="barlowbold mb-1 mb-lg-3">
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "HOME.ENLACES" }),
                  }}
                />
              </div>
              <Link to="/" style={{ textDecoration: "none" }}>
                <ul className="nav-item" style={{ color: "white" }}>
                  HOME
                </ul>
              </Link>
              <Link to="/ladestileria" style={{ textDecoration: "none" }}>
                <ul className="nav-item" style={{ color: "white" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: "MENU.THEDISTILERY" }),
                    }}
                  />
                </ul>
              </Link>
              <Link to="/productos" style={{ textDecoration: "none" }}>
                <ul className="nav-item" style={{ color: "white" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: "MENU.PRODUCTS" }),
                    }}
                  />
                </ul>
              </Link>
              <Link to="/contacto" style={{ textDecoration: "none" }}>
                <ul className="nav-item" style={{ color: "white" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: "MENU.CONTACT" }),
                    }}
                  />
                </ul>
              </Link>
              <Link to="/descargas" style={{ textDecoration: "none" }}>
                <ul className="nav-item" style={{ color: "white" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: "MENU.DOWNLOADS" }),
                    }}
                  />
                </ul>
              </Link>
              <div className="barlowbold mt-2 mt-lg-5">
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "HOME.REDESSOCIALES" }),
                  }}
                />
              </div>
              <a
                href="https://www.linkedin.com/company/internacional-de-comercio-y-destilerias-j-borrajo-s-a/"
                target="_blank"
              >
                <img
                  className="my-2 my-lg-4"
                  src={icolinkedinwhite}
                  style={{ marginLeft: "33px" }}
                  alt="linkedin"
                  title="linkedin"
                />
              </a>
              <div className="barlowbold mb-1 mb-lg-4">LEGAL</div>
              <Link
                to="/politica-privacidad"
                style={{ textDecoration: "none" }}
              >
                <ul className="nav-item" style={{ color: "white" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "HOME.POLITICAPRIVACIDAD",
                      }),
                    }}
                  />
                </ul>
              </Link>
              <Link to="/politica-cookies" style={{ textDecoration: "none" }}>
                <ul className="nav-item" style={{ color: "white" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "HOME.POLITICACOOKIES",
                      }),
                    }}
                  />
                </ul>
              </Link>
              <Link to="/politica-inocuidad" style={{ textDecoration: "none" }}>
                <ul className="nav-item" style={{ color: "white" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "HOME.POLITICAINOCUIDAD",
                      }),
                    }}
                  />
                </ul>
              </Link>
            </div>
            <div className="col-12 col-lg-5 my-5 my-lg-0">
              <div className="barlowbold">
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "HOME.CONTACTO" }),
                  }}
                />
              </div>
              <div>
                Internacional de Comercio y Destilerías
                <br />
                J.Borrajo S.A.
                <br />
                C/Francisco Redondo, 10
                <br />
                45600 Talavera de la Reina
                <br />
                <a href="tel:+34925802004" className="no-underline-white">
                  +34 925 80 20 04
                </a>
                <br />
              </div>
              <a
                href="mailto:comunicacion@borrajo.com"
                className="no-underline-white"
              >
                comunicacion@jborrajo.com
              </a>
              <div
                className="my-4"
                style={{ height: "252px", width: "auto", overflow: "hidden" }}
              >
                {!isLoaded ? (
                  <h1>Loading...</h1>
                ) : (
                  <GoogleMap
                    mapContainerClassName="map-container h-100"
                    center={center}
                    zoom={16}
                  >
                    <Marker position={center} />
                  </GoogleMap>
                )}
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <FormattedMessage id="HOME.NAME" />
              <input
                id={`name`}
                onChange={handleChangeContact("name")}
                type="text"
                placeholder={intl.formatMessage({ id: "HOME.NAME" })}
                value={contact.name}
                required
                className="w-100"
              />
              <FormattedMessage id="HOME.EMAIL" />
              <input
                id={`email`}
                onChange={handleChangeContact("email")}
                type="email"
                placeholder={intl.formatMessage({ id: "HOME.EMAIL" })}
                value={contact.email}
                required
                className="w-100"
              />
              <FormattedMessage id="HOME.SUBJECT" />
              <input
                id={`subject`}
                onChange={handleChangeContact("subject")}
                type="text"
                placeholder={intl.formatMessage({ id: "HOME.SUBJECT" })}
                value={contact.subject}
                required
                className="w-100"
              />
              <FormattedMessage id="HOME.MESSAGE" />
              <textarea
                id={`message`}
                onChange={handleChangeContact("message")}
                type="text"
                placeholder={intl.formatMessage({ id: "HOME.MESSAGE" })}
                value={contact.message}
                required
                className="w-100"
              ></textarea>
              <button className="mt-2" onClick={() => saveContact()}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "HOME.SEND" }),
                  }}
                />
              </button>
              <p className="mt-1" style={{ textAlign: "justify" }}>
                <FormattedMessage id="HOME.DESCRIPTIONFOOTER" />
              </p>
            </div>
            <img
              src={kitdigital}
              className="kitdigital"
              alt="KIT Digital - red.es - Financiado por la unión europea"
            />
          </div>
        </div>
      </footer>
    </div>
  );
}

export default injectIntl(App);
