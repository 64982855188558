import React, { useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";

const PoliticaInocuidad = () => {
  const intl = useIntl();
  const lang = useLang();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const objectiveKeys = [
    "POLITICAINOCUIDAD.OBJECTIVE_1",
    "POLITICAINOCUIDAD.OBJECTIVE_2",
    "POLITICAINOCUIDAD.OBJECTIVE_3",
    "POLITICAINOCUIDAD.OBJECTIVE_4",
    "POLITICAINOCUIDAD.OBJECTIVE_5",
    "POLITICAINOCUIDAD.OBJECTIVE_6",
  ];

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "POLITICAINOCUIDAD.TITLESEO" })}
        description={intl.formatMessage({
          id: "POLITICAINOCUIDAD.DESCRIPTIONSEO",
        })}
      />
      <div className="container">
        <div className="row">
          <div className="d-grid gap-3">
            <h1>{intl.formatMessage({ id: "POLITICAINOCUIDAD.HEADING" })}</h1>
            <p>
              <FormattedMessage
                id="POLITICAINOCUIDAD.INTRO"
                values={{
                  b: (chunks) => <b>{chunks}</b>,
                }}
              />
            </p>
            <p>
              {intl.formatMessage({ id: "POLITICAINOCUIDAD.OBJECTIVES_INTRO" })}
            </p>
            <ul>
              {objectiveKeys.map((key, index) => (
                <li key={index}>{intl.formatMessage({ id: key })}</li>
              ))}
            </ul>
            <p>{intl.formatMessage({ id: "POLITICAINOCUIDAD.CONCLUSION" })}</p>
            <p>{intl.formatMessage({ id: "POLITICAINOCUIDAD.SIGNATURE" })}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PoliticaInocuidad;
